<template>
  <div>
    <tiptap-vuetify
        v-model="localValue"
        :extensions="extensions"
    />
  </div>
</template>
<script>
import {extensions} from "@/plugins/tiptapVuetify";
import {TiptapVuetify} from "tiptap-vuetify";
export default {
  name: "TextEditor",
  components: {TiptapVuetify},
  props: {
    value: {
      type: String,
      required: true
    },
  },
  data()  {
    return {
      localValue: this.value,
      content: "",
      extensions,
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .tiptap-vuetify-editor .ProseMirror {
  min-height: 100px;
  max-height: 100px;
  overflow: hidden;
  padding: 10px;
  p {
    margin-bottom: 0;
  }
}
</style>