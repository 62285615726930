<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        v-if="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{post.id  ? 'Post bearbeiten' : 'Neuer Post'}}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-checkbox v-if="showAlertCheckbox" v-model="post.alert" label="Alarm" />
                </v-col>
                <v-col cols="12">
                  <v-select
                      v-model="post.categoryId"
                      :items="availableCategories"
                      item-text="name"
                      item-value="id"
                      label="Kategorie auswählen"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="post.title"
                      label="Title"
                      required
                      :rules="requiredRule('Titel')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="post.excerpt"
                      label="Kurzbeschreibung"
                      required
                      :rules="requiredRule('Kurzbeschreibung')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-input class="mb-12 mt-16" v-model="post.content" :rules="requiredRule('Inhalt')">
                    <v-row>
                      <v-col cols="12" class="pb-0">Inhalt</v-col>
                      <v-col cols="12" class="pl-0">
                        <text-editor
                            v-model="post.content"
                            label="Inhalt"
                            :rules="requiredRule('Inhalt')"
                        />
                      </v-col>
                    </v-row>
                  </v-input>
                </v-col>
                <v-col cols="12">
                  <image-input
                      v-model="post.image"
                      label="Bild"
                  />
                </v-col>
                <v-col
                    cols="12"
                >
                  <my-date-picker label="Veröffentlichungs-Datum" v-model="post.publishingDate"/>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Schließen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="savePost"
          >
            {{ post.id ? 'Speichern' : 'Hinzufügen'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>
<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/admin/Snackbar'
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import {mapState} from "vuex";
import ImageInput from "@/components/common/ImageInput";
import {extensions} from "@/plugins/tiptapVuetify";
import TextEditor from "@/components/common/TextEditor.vue";
import MyDatePicker from "@/components/common/MyDatePicker.vue";

const NEW_POST_TEMPLATE = {
  publishingDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
}
export default {
  name: 'PostEditDialog',
  components: {MyDatePicker, Snackbar, ConfirmDialog, ImageInput, TextEditor },
  data: () => ({
    categories: [],
    extensions,
    showDialog: false,
    post: NEW_POST_TEMPLATE,
    snackbarSuccess: {
      text: 'Post erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    menu: false,
  }),
  methods: {
    show (post) {
      if(post)  {
        this.post = { ...post }
        delete this.post.category
      } else {
        this.post = {... NEW_POST_TEMPLATE, userId: this.user.id }
      }
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.post = {}
      this.$refs.userForm.resetValidation()
    },
    async savePost () {
      if (!this.$refs.userForm.validate()) {
        return
      }
      this.post.image = this.post.image?.id
      if (this.post.id) {
        APIService.updatePost(this.post).then((response) => {
          console.log("Update Response", response, this.posts)
          this.$emit('updated', this.post)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        APIService.savePost(this.post).then((post) => {
          console.log("Save Response", post, this.posts)
          this.$emit('saved', post)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      }
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
  },
  computed: {
    ...mapState(['user']),
    computedDateFormattedDatefns () {
      return this.post.publishingDate ? format(parseISO(this.post.publishingDate), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
    availableCategories() {
      return this.categories.filter(category => category.types?.includes('post') && !category.restricted);
    },
    showAlertCheckbox() {
      return this.categories.find(category => category.id === this.post.categoryId)?.cluster?.manager?.includes(this.user.id)
    }
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    }
  },
  async mounted() {
    const [categories, clusters] = await Promise.all([
      APIService.getCompanyCategories(this.user.companyId),
      APIService.getClusters(),
    ])
    const managedClusterIds = clusters.filter(cluster => cluster.manager?.includes(this.user.id)).map(cluster => cluster.id)
    this.categories = categories.filter(category => !category.clusterManagerPostOnly || managedClusterIds.includes(category.cluster))
    this.categories = this.categories.map(category => ({...category, cluster: clusters.find(cluster => cluster.id === category.cluster)}))
  }
}
</script>
