<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        v-if="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{event.id  ? 'Veranstaltung bearbeiten' : 'Neue Veranstaltung'}}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                      v-model="event.categoryId"
                      :items="availableCategories"
                      item-text="name"
                      item-value="id"
                      label="Kategorie auswählen"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="event.title"
                      label="Title"
                      required
                      :rules="requiredRule('Titel')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="event.excerpt"
                      label="Kurzbeschreibung"
                      required
                      :rules="requiredRule('Kurzbeschreibung')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-input class="mb-12 mt-16" v-model="event.content" :rules="requiredRule('Inhalt')">
                    <v-row>
                      <v-col cols="12" class="pb-0">Inhalt</v-col>
                      <v-col cols="12" class="pl-0">
                        <text-editor
                            v-model="event.content"
                            label="Inhalt"
                            :rules="requiredRule('Inhalt')"
                        />
                      </v-col>
                    </v-row>
                  </v-input>>
                </v-col>
                <v-col cols="12">
                  <image-input
                      v-model="event.image"
                      label="Bild"
                  />
                </v-col>
                <v-col cols="12">
                  <date-time-picker label="Beginn" v-model="event.startDate"/>
                </v-col>
                <v-col cols="12">
                  <date-time-picker label="Ende" v-model="event.endDate"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="event.location"
                      label="Ort"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <my-date-picker label="Veröffentlichungs-Datum" v-model="event.publishingDate"/>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Schließen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveEvent"
          >
            {{ event.id ? 'Speichern' : 'Hinzufügen'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>
<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/admin/Snackbar'
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";
import {mapState} from "vuex";
import ImageInput from "@/components/common/ImageInput";
import DateTimePicker from "@/components/common/DateTimePicker";
import {extensions} from "@/plugins/tiptapVuetify";
import TextEditor from "@/components/common/TextEditor.vue";
import MyDatePicker from "@/components/common/MyDatePicker.vue";

const NEW_POST_TEMPLATE = {
  categoryId: 'feed',
  content: '',
  publishingDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
}
export default {
  name: 'EventEditDialog',
  components: {MyDatePicker, Snackbar, ConfirmDialog, ImageInput, DateTimePicker, TextEditor },
  data: () => ({
    categories: [],
    extensions,
    showDialog: false,
    event: NEW_POST_TEMPLATE,
    snackbarSuccess: {
      text: 'Event erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    menu: false,
  }),
  methods: {
    show (event) {
      if(event)  {
        this.event = { ...event }
        delete this.event.category
      } else {
        this.event = {... NEW_POST_TEMPLATE, userId: this.user.id }
      }
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.event = {}
      this.$refs.userForm.resetValidation()
    },
    async saveEvent () {
      if (!this.$refs.userForm.validate()) {
        return
      }
      this.event.image = this.event.image?.id
      if (this.event.id) {
        APIService.updateEvent(this.event).then((response) => {
          console.log("Update Response", response, this.events)
          this.$emit('updated', this.event)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        APIService.saveEvent(this.event).then((event) => {
          console.log("Save Response", event, this.events)
          this.$emit('saved', event)
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      }
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
  },
  computed: {
    ...mapState(['user']),
    computedDateFormattedDatefns () {
      return this.event.publishingDate ? format(parseISO(this.event.publishingDate), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
    availableCategories() {
      return this.categories.filter(category => category.types?.includes('event') && !category.restricted);
    },
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    }
  },
  mounted() {
    APIService.getCategories().then((categories) => {
      console.log("Categories", categories)
      this.categories = categories
    })
  }

}
</script>

<style lang="scss" scoped>
</style>