<template>
  <div>
    <h1>Posts</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createPost"
            >
              <v-icon left>mdi-plus</v-icon>
              Post
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="posts"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :hide-default-footer="posts.length < 5"
      >
        <template v-slot:item.publishingDate="{ item }">
          {{ computedDateFormattedDatefns(item.publishingDate) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editPost(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deletePost(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <post-edit-dialog ref="postEditDialog" @updated="postUpdated" @saved="postSaved"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import PostEditDialog from '@/components/admin/PostEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import {format, parseISO} from 'date-fns'
import {de} from 'date-fns/locale'
import store from "@/store/index";
import tiptapVuetifyPlugin from "@/plugins/tiptapVuetify";

export default {
  name: "PostList",
  components: {
    PostEditDialog,
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      categories: [],
      cluseters: [],
      headers: [
        {
          text: 'Title',
          align: 'start',
          sortable: true,
          value: 'title'
        },
        {text: 'Veröffentlichungs-Datum', value: 'publishingDate'},
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      posts: []
    }
  },
  methods: {
    createPost() {
      this.$refs.postEditDialog.show()
    },
    async editPost(post) {
      console.log("Item", post)
      const _post = await APIService.getPost(post.id)
      this.$refs.postEditDialog.show(_post)
    },
    deletePost: function (post) {
      this.$refs.confirmDelete.show({
        title: 'Post entfernen',
        text: `Wollen sie den Post <b>${post.title}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(() => {
        APIService.deletePost(post.id).then(() => {
          this.posts = this.posts.filter((p) => p.id !== post.id)
          console.log('Item deleted')
        })
      })
    },
    postSaved(post) {
      console.log("Saved", post)
      this.posts = [...this.posts, post]
    },
    postUpdated(post) {
      console.log("updated", post)
      this.posts = this.posts.map((p) => post.id === p.id ? post : p)
    },
    computedDateFormattedDatefns(date) {
      return date ? format(parseISO(date), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
    getCategoryName(categoryId) {
      const category = this.categories.find(category => category.id === categoryId)
      return category?.name ?? "-"
    }
  },
  async beforeRouteEnter(to, from, next) {
    const [posts, categories, clusters] = await Promise.all(
        [
          APIService.getPostsByUserId(store.state?.user?.id),
          APIService.getPostCategories(),
        ],
    )
    console.log(categories, clusters)
    next(vm => {
      vm.posts = posts.map((post) => {
        return {...post, category: categories.find(category => category.id === post.category)}
      })
      vm.categories = categories
      vm.clusters = clusters
    })
  },
  created() {
    tiptapVuetifyPlugin(this.$vuetify);
  }
}
</script>

<style scoped>

</style>
