<template>
  <div>
    <div class="text-h5">Firmendaten</div>
    <v-form>
      <v-row>
        <v-col cols="12">
          <v-text-field  label="Firmenname" v-model="company.name"></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-select v-model="company.type" :items="unitTypes" item-value="id" item-text="name" label="Typ"></v-select>
        </v-col>
        <v-col cols="10">
          <v-text-field  label="Strasse" v-model="company.address.street"></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field  label="Hausnummer" v-model="company.address.houseNumber"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field  label="PLZ" v-model="company.address.zip"></v-text-field>
        </v-col>
        <v-col cols="10">
          <v-text-field label="Ort" v-model="company.address.city"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field label="AP-Vorname" v-model="company.contact.firstName"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field label="AP-Nachname" v-model="company.contact.lastName"></v-text-field>
        </v-col>
      </v-row>
      <v-text-field  label="Telefon" v-model="company.contact.phone"></v-text-field>
      <v-text-field  label="Email" v-model="company.contact.email"></v-text-field>
      <v-text-field  label="Website" v-model="company.contact.homepage"></v-text-field>
      <v-text-field  label="Facebook" v-model="company.contact.facebook"></v-text-field>
      <v-text-field  label="Instagram" v-model="company.contact.instagram"></v-text-field>
      <v-text-field  label="Click2Meet" v-model="company.contact.clickToMeet"></v-text-field>

      <div class="mb-1 mt-2">Beschreibung</div>
      <text-editor
          v-model="company.info.description"
      />
      <div class="mb-1 mt-6">Öffnungszeiten</div>
      <text-editor
          v-model="company.info.openingHours"
      />
      <image-input
          v-model="company.logo"
          label="Firmenlogo"
      />
      <image-input
          v-model="company.info.storeWindowImage1"
          label="Schaufenster Bild1"
      />
      <image-input
          v-model="company.info.storeWindowImage2"
          label="Schaufenster Bild2"
      />
      <image-input
          v-model="company.info.storeWindowImage3"
          label="Schaufenster Bild3"
      />
      <image-input
          v-model="company.info.storeWindowImage4"
          label="Schaufenster Bild4"
      />
      <div>
        <template v-if="categories">
          <h4>Kategorien</h4>
          <v-row>
            <v-col cols="12"><v-select v-model="company.primaryCategory" label="Primäre Kategorie" :items="selectableCategories" item-text="name" item-value="id"></v-select></v-col>
          </v-row>
          <div :key="cluster.id" v-for="cluster in company.clusters">
            <v-row>
              <v-col cols="12" class="text-decoration-underline">{{cluster.name}}</v-col>

              <v-col
                  v-for="rootCategory in getSelectableCategories(cluster.id)"
                  cols="4" class="my-0 py-0"
                  :key="rootCategory.id">
                <v-checkbox class="my-0 py-0" v-model="selectedCategories[rootCategory.id]"  :label="rootCategory.name"></v-checkbox>
              </v-col>
              <template v-for="parentCategory in getParentCategories(cluster.id)">
                <v-col :key="parentCategory.id" cols="12" class="my-0 py-0" >
                  <div class="parent-category">{{parentCategory.name}}</div>
                </v-col>
                <v-col cols="4" class="my-0 py-0" :key="category.id"  v-for="category in getSelectableCategories(cluster.id, parentCategory.id)">
                  <v-checkbox class="my-0 py-0" v-model="selectedCategories[category.id]"  :label="category.name"></v-checkbox>
                </v-col>
              </template>
            </v-row>
          </div>
        </template>
        <template v-if="tags">
          <h4>Tags</h4>
          <div :key="'tag_' + cluster.id" v-for="cluster in company.clusters">
            <div v-if="getClusterTags(cluster.id).length">
              <v-row>
                <v-col cols="12" class="text-decoration-underline">{{cluster.name}}</v-col>
                <v-col cols="4" class="my-0 py-0" :key="tag.id"  v-for="tag in getClusterTags(cluster.id)">
                  <v-checkbox class="my-0 py-0" v-model="selectedTags[tag.id]"  :label="tag.name"></v-checkbox>
                </v-col>
              </v-row>
            </div>
          </div>
        </template>
      </div>
      <v-btn :disabled="saveDisabled" color="primary" @click="saveProfile">Profil speichern</v-btn>
    </v-form>
  </div>
</template>

<script>
import APIService from "@/services/APIService";
import {mapState} from "vuex";
import store from "@/store"
import ImageInput from "@/components/common/ImageInput";
import {extensions} from "@/plugins/tiptapVuetify";
import tiptapVuetifyPlugin from "@/plugins/tiptapVuetify";
import TextEditor from "@/components/common/TextEditor.vue";

export default {
  components: {TextEditor, ImageInput },
  name: "Profile",
  data() {
    return {
      extensions,
      company: {
        contact: {},
        address: {},
        info: {},
        tags: {},
      },
      tags: [],
      categories: [],
      selectedTags: {},
      selectedCategories: {},
      saveDisabled: false,
      unitTypes: [
        { name: 'Firma', id: 'company' },
        { name: 'Kommunale Einrichtung', id: 'public' },
        { name: 'Verein', id: 'association' }
      ]
    }
  },
  methods: {
    async saveProfile() {
      this.company.tags = Object.keys(this.selectedTags).filter(key => this.selectedTags[key] === true)
      const company = JSON.parse(JSON.stringify(this.company))
      company.categories = Object.keys(this.selectedCategories).filter(key => this.selectedCategories[key] === true).filter(key =>  key !== company.primaryCategory )
      company.logo = company.logo?.id
      company.info.storeWindowImage1 = company.info.storeWindowImage1?.id
      company.info.storeWindowImage2 = company.info.storeWindowImage2?.id
      company.info.storeWindowImage3 = company.info.storeWindowImage3?.id
      company.info.storeWindowImage4 = company.info.storeWindowImage4?.id
      if(company.id) {
        await APIService.updateCompanyByUserId(this.user.id, company)
      } else {
        this.saveDisabled=true
        this.company = await APIService.saveCompany(this.user.id, company)
        this.company.id = company.id
        this.saveDisabled=false
      }
    },
    getParentCategories(cluster = undefined) {
      return this.getClusterCategories(cluster).filter(cat => cat.hasChildren)
    },
    getSelectableCategories(cluster, parentId = null) {
      const categoryFilter = parentId ? cat => cat.parent === parentId : cat => !cat.parent && !cat.hasChildren
      return this.getClusterCategories(cluster).filter(categoryFilter)
    },
    getClusterCategories(cluster = undefined) {
      return cluster ? this.categories.filter(category => category.cluster === cluster) : this.categories.filter(category => category.cluster === undefined )
    },
    getClusterTags(cluster = undefined) {
      return cluster ? this.tags.filter(tag => tag.cluster === cluster) : this.tags.filter(tag => tag.cluster === undefined )
    }
  },
  computed: {
    ...mapState(['user']),
    selectableCategories() {
      return this.categories.filter(cat => cat.parent || (!cat.parent && !cat.hasChildren))
    }
  },
  async beforeRouteEnter(to, from, next) {
    const user = store?.state?.user
    console.log("Before route enter profile", store?.state?.user)

    const allRequests = []
    allRequests.push(APIService.getCompanyByUserId(user.id))
    if(user.companyId) {
      allRequests.push(APIService.getCompanyTags(user.companyId))
      allRequests.push(APIService.getCompanyCategories(user.companyId))
    }
    const [company, tags, categories ] = await Promise.all(allRequests)
    next(vm => {
      const selectedTags = {}
      const selectedCategories = {}
      if(company) {
        vm.company = company
        company.tags.map(tag => selectedTags[tag] =  true)
        company.categories.map(tag => selectedCategories[tag] =  true)
        vm.selectedTags = selectedTags
        vm.selectedCategories = selectedCategories
      }
      vm.tags = tags
      vm.categories  = categories.filter(cat => cat.types.includes('company'))
    })
  },
  created() {
    tiptapVuetifyPlugin(this.$vuetify);
  }
}
</script>

<style lang="scss" scoped>
form {
  width: 70%;
}
.parent-category {
  font-weight: bold;
}
</style>
