<template>
  <div>
    <h1>Veranstaltungen</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createEvent"
            >
              <v-icon left>mdi-plus</v-icon>
              Veranstaltung
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="events"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
          :hide-default-footer="events.length < 5"
      >
        <template v-slot:item.category="{ item }">
          {{getCategoryName(item.categoryId)}}
        </template>
        <template v-slot:item.publishingDate="{ item }">
          {{ item.publishingDate | formatLocaleDate}}
        </template>
        <template v-slot:item.startDate="{ item }">
          {{ item.startDate | formatLocaleDateTime}}
        </template>
        <template v-slot:item.endDate="{ item }">
          {{ item.endDate | formatLocaleDateTime}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editEvent(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteEvent(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <event-edit-dialog ref="eventEditDialog" @updated="eventUpdated" @saved="eventSaved"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import EventEditDialog from '@/components/admin/EventEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import { format, parseISO } from 'date-fns'
import { de } from 'date-fns/locale'
import store from "@/store/index";
import tiptapVuetifyPlugin from "@/plugins/tiptapVuetify";

export default {
  name: "EventList",
  components: {
    EventEditDialog,
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      categories: [],
      headers: [
        {
          text: 'Titel',
          align: 'start',
          sortable: true,
          value: 'title'
        },
        { text: 'Veröffentlichungs-Datum', value: 'publishingDate' },
        { text: 'Beginn', value: 'startDate' },
        { text: 'Ende', value: 'endDate' },

        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      events: []
    }
  },
  methods: {
    createEvent () {
      this.$refs.eventEditDialog.show()
    },
    async editEvent(event) {
      console.log("Item", event)
      const _event = await APIService.getEvent(event.id)
      this.$refs.eventEditDialog.show(_event)
    },
    deleteEvent: function (event) {
      this.$refs.confirmDelete.show({
        title: 'Veranstaltung entfernen',
        text: `Wollen sie die Veranstaltung <b>${event.title}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(() => {
        APIService.deletePost(event.id).then(() => {
          this.events = this.events.filter((p) => p.id !== event.id)
          console.log('Item deleted')
        })
      })
    },
    eventSaved(event) {
      console.log("Saved", event)
      this.events = [...this.events, event]
    },
    eventUpdated(event) {
      console.log("updated", event)
      this.events = this.events.map((p) => event.id === p.id ? event : p)
    },
    computedDateFormattedDatefns (date) {
      return date ? format(parseISO(date), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
    getCategoryName(categoryId) {
      const category = this.categories.find(category => category.id === categoryId)
      return category?.name ?? "-"
    }
  },
  async beforeRouteEnter(to, from, next) {
    const events = await APIService.getEventsByUserId(store.state?.user?.id)
    next(vm => {
      vm.events = events
    })
  },
  created() {
    tiptapVuetifyPlugin(this.$vuetify);
  }

}
</script>

<style scoped>

</style>
