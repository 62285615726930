import Vue from "vue";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import { Bold, Italic, Underline, BulletList, OrderedList, ListItem, Link } from 'tiptap-vuetify'

export const extensions = [
    Bold,
    Italic,
    Underline,
    Link,
    ListItem,
    BulletList,
    OrderedList,
]

export default vuetify => {

    if (!window.tiptapVuetifyLoaded) {
        window.tiptapVuetifyLoaded = true
        Vue.use(TiptapVuetifyPlugin, {
            vuetify: { ...vuetify, framework: { lang: vuetify.lang } },
            iconsGroup: "mdi",
        });
    }
};