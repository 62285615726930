<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :value="computedDateFormattedDatefns"
          label="Veröffentlichungs-Datum"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        no-title
        scrollable
        @input="menu=false"
        first-day-of-week="1"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import {format, parseISO} from "date-fns";
import {de} from "date-fns/locale";

export default {
  name: "MyDatePicker",
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      menu: false,
      date: this.value

    }
  },
  computed: {
    computedDateFormattedDatefns () {
      return this.date ? format(parseISO(this.date), 'EEEE, dd.MM.yyyy', {locale: de}) : ''
    },
  },
  watch: {
    date: function (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>

</style>